<template>
    <div id="Verification" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 py-lg-5">
                <p class="manrope-title">
                    Listado de Vehiculos a Verificar
                </p>
            </div>

            <div class="col-sm-12 col-lg-6 px-0 pb-3 pb-lg-0 pt-lg-5">
                <div class="row align-items-center">
                    <div class="col-12 col-md-6 px-3 pb-2 pb-xl-0 px-lg-0">
                        <button @click.stop="expired_verifications = !expired_verifications"
                            class="btn btn-outline-primary btn-pill w-100"
                            type="button">
                            Verificaciones vencidas
                        </button>
                    </div>

                    <div class="col-12 col-md-6 position-relative">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>

            <div class="col-12" v-if="empty_search">
                <p>
                    No se han encontrado elementos durante la búsqueda.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search && !expired_verifications && verification_data.length > 0">
                <li-table :data="verification_data" 
                    :allow_headers="verification_headers"
                    pagination_off>
                    <template v-slot:plate="data">
                        <div>
                            <b-icon icon="exclamation-octagon" variant="danger" v-if="data.item.cantidad_vencidos > 0"/>
                            <span class="pl-2">{{ data.item.plate }}</span>
                        </div>
                    </template>
                    <template v-slot:gummed_name="data">
                        <div class="d-flex justify-content-between">
                            <span>{{ data.item.gummed_name.toUpperCase() }}</span>
                            <div :style="`width: 20px; height: 20px; background: #${data.item.gummed_hex}`"></div>
                        </div>
                    </template>
                    <template v-slot:remaining_days="data">
                        <span>
                            Faltan: {{ data.item.remaining_days}} dias
                        </span>
                    </template>
                    <template v-slot:immediate_verification="data">
                        {{ new Date(data.item.immediate_verification).toLocaleDateString() }}
                    </template>
                    <template v-slot:detail="data">
                        <button @click.stop="selectVerification('detail', data.item.id_vehicle, data.item)"
                            class="btn btn-outline-info btn-pill">
                            detalles
                        </button>
                    </template>
                    <template v-slot:add="data">
                        <button @click.stop="selectVerification('add', data.item.id_vehicle)"
                            
                            class="btn btn-outline-primary btn-pill">
                            Añadir verificación
                        </button>
                    </template>
                </li-table>
            </div>

            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search && expired_verifications && expired_verifications_data.length > 0">
                <li-table :data="expired_verifications_data" 
                    :allow_headers="expired_verifications_headers"
                    pagination_off>
                    <template v-slot:action="data">
                        <button @click.stop="selectVerification('expired', data.item.id)"
                            class="btn btn-outline-success btn-pill"
                            type="button">
                            Completar
                        </button>
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12" v-if="!fetch_error && !empty_search && verification_data.length > 0">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="mr-3 opensans-bold">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        class="form-control bg-secondary text-white border-0"
                        :disabled="page > 1"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'Sin vencimiento proximo'}, {id: '2', label: 'Proximos a vencer'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        
        <!-- Modal-->

        <li-modal :show_modal="verification_modal" :max_width="1200" class="px-3">
                <div class="col-12 col-md-6">
                    <h3>
                        Añadir verificación
                    </h3>
                </div>

                <div class="col-12 col-md-6 d-flex justify-content-md-end">
                    <button @click.stop="addVerification"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="date = null; selected_id = null; verification_modal = false;"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

                <div class="col-12 p-2 opensans-bold">
                    <li-input label="Fecha de verificación"
                        v-model="date"
                        type="date"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.date">
                        {{ errors.date }}
                    </span>
                </div>

        </li-modal>

        <li-modal :show_modal="view_modal" :max_width="1200" class="px-3">
                <div class="col-12 col-md-6">
                    <h3>
                        Detalles
                    </h3>
                </div>

                <div class="col-12 col-md-6 d-flex justify-content-md-end">
                        
                    <button @click.stop="view_data = {}; view_modal = false;"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

                <div class="col-6">
                    <p style="margin: 0px; font-size: 20px;">
                        Placas:
                    </p>
                    <span class="pl-3">
                        {{ view_data.plate }}
                    </span>
                    <hr style="border: 1px solid #008c9c;" class="m-0">
                </div>

                <div class="col-6">
                    <p style="margin: 0px; font-size: 20px;">
                        Modelo:
                    </p>
                    <span class="pl-3">
                        {{ view_data.model_name }}
                    </span>
                    <hr style="border: 1px solid #008c9c;" class="m-0">
                </div>

                <div class="col-6">
                    <p style="margin: 0px; font-size: 20px;">
                        holograma:
                    </p>
                    <span class="pl-3">
                        {{ view_data.hologram }}
                    </span>
                    <hr style="border: 1px solid #008c9c;" class="m-0">
                </div>

                <div class="col-6">
                    <p style="margin: 0px; font-size: 20px;">
                        Engomado:
                    </p>
                    <span class="pl-3 d-flex">
                        {{ view_data.gummed_name }}
                        <div class="ml-3" :style="`width: 20px; height: 20px; background: #${view_data.gummed_hex}`"></div>
                    </span>
                    <hr style="border: 1px solid #008c9c;" class="m-0">
                </div>

                <div class="col-6">
                    <p style="margin: 0px; font-size: 20px;">
                        Año:
                    </p>
                    <span class="pl-3">
                        {{ view_data.year }}

                    </span>
                    <hr style="border: 1px solid #008c9c;" class="m-0">
                </div>

                <div class="col-6">
                    <p style="margin: 0px; font-size: 20px;">
                        verificaciones vencidas:
                    </p>
                    <span class="pl-3 text-danger">
                        {{ view_data.cantidad_vencidos }}
                    </span>
                    <hr style="border: 1px solid #008c9c;" class="m-0">
                </div>

                <div class="col-6">
                    <p style="margin: 0px; font-size: 20px;">
                        Fecha limite:
                    </p>
                    <span class="pl-3">
                        {{ new Date(view_data.immediate_verification).toLocaleDateString() }}
                    </span>
                    <hr style="border: 1px solid #008c9c;" class="m-0">
                </div>

                <div class="col-6">
                    <p style="margin: 0px; font-size: 20px;">
                        Fecha siguiente periodo:
                    </p>
                    <span class="pl-3">
                        {{ new Date(view_data.next_verification).toLocaleDateString() }}
                    </span>
                    <hr style="border: 1px solid #008c9c;" class="m-0">
                </div>

                <div class="col-6">
                    <p style="margin: 0px; font-size: 20px;">
                        Dias restantes:
                    </p>
                    <span class="pl-3">
                        Faltan {{ view_data.remaining_days }} dias
                    </span>
                    <hr style="border: 1px solid #008c9c;" class="m-0">
                </div>

                
        </li-modal>

        <li-modal :show_modal="expired_modal" :max_width="1200" class="px-3">
                <div class="col-12 col-md-6">
                    <h3>
                        Completar Verificación
                    </h3>
                </div>

                <div class="col-12 col-md-6 d-flex justify-content-md-end">
                    <button @click.stop="completeExpiredVerification"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="expired_date = null; selected_id = null; expired_modal = false;"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

                <div class="col-12 p-2 opensans-bold">
                    <li-input label="Fecha de verificación"
                        v-model="expired_date"
                        type="date"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.expired_date">
                        {{ errors.expired_date }}
                    </span>
                </div>
                
        </li-modal>

    </div>
</template>

<script>

    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                selected_id: null,
                verification_modal: false,
                expired_verifications: false,
                expired_modal: false,
                view_modal: false,
                verification_headers: [
                    { key: 'plate', label: 'Placa'},
                    { key: 'model_name', label: 'Modelo'},
                    { key: 'gummed_name', label: 'Engomado', width: 10},
                    { key: 'hologram', label: 'Holograma', width: 10},
                    { key: 'remaining_days', label: 'Dias restantes'},
                    { key: 'immediate_verification', label: 'Fecha limite'},
                    { key: 'detail', width: 5},
                    { key: 'add', width: 15},
                ],
                verification_data: [],
                expired_verifications_headers: [
                    { key: 'plate', label: 'Placa'},
                    { key: 'model_name', label: 'Modelo'},
                    { key: 'gummed_name', label: 'Engomado'},
                    { key: 'hologram', label: 'Holograma'},
                    { key: 'action', width: 10}
                ],
                expired_verifications_data: [
                    { plate: 'JSC564T', model_name: 'Sentra', gummed_name: 'Amarillo', hologram: '00', action: '-'}
                ],
                date: null,
                expired_date: null,
                errors: {
                    date: null,
                    expired_date: null
                },
                view_data: {},
                //pagination
                filter: 'all',
                page: 1,
                rows: 10,
                order_asc: '2',
            }
        },
        computed: {
            ...mapGetters({
                verifications: 'verifications/getVerifications',
                last_page: 'verifications/getLastPage',
                total_pages: 'verifications/getTotalPages'
            })
        },
        methods: {
            //helpers
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? true : false;
                
                switch(this.filter) {
                    case 'all':
                        await this.$store.dispatch('verifications/listVerifications', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.verifications === null) {
                    this.empty_search = true
                    return
                }

                else {
                    this.empty_search = false
                }
                
               this.verification_data = this.verifications.map( verification => {
                    return {
                        ...verification,
                        detail: '-',
                        add: '-'
                    }
                })
              
            },
            selectVerification(type, id, data){
                switch(type){
                    case 'add':
                        this.selected_id = id
                        this.verification_modal = true
                        break;
                    case 'detail':
                        this.view_data = data
                        this.view_modal = true
                        break;
                    case 'expired':
                        this.selected_id = id
                        this.expired_modal = true
                        break;
                    default:
                        console.log('default')
                        return;
                }
            },

            //process
            async addVerification(){

                this.errors.date = null
                let complete = true

                if(_.isNull(this.date) || _.isEmpty(this.date)) {
                    this.errors.date = '* Campo obligatorio'
                    complete = false
                }

                if(complete) {
                    const payload = {
                        date: this.date,
                        id_vehicle: this.selected_id
                    }

                    await this.$store.dispatch('verifications/addVerification', payload)
                    this.filterElements()
                    this.selected_id = null
                    this.date = null
                    this.verification_modal = null

                }
            },
            async completeExpiredVerification() {
                
                this.errors.expired_date = null

                let complete = true;

                if(_.isNull(this.expired_date) && _.isEmpty(this.expired_date)) {
                    this.errors.expired_date = '*Campo obligatorio'
                    complete = false
                }

                if(complete) {
                    const payload = {
                        date: this.expired_date,
                        id_verification: this.id_verification
                    }

                    await this.$store.dispatch('verifications/expiredVerification', payload);
                    this.filterElements();
                    this.expired_modal = false;
                }


            },
            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            try {

                //lista de verificaciones
                await this.$store.dispatch('verifications/listVerifications', {datatable: true, page: this.page, rows: this.rows, status: null, order_asc: true} )

                this.verification_data = this.verifications.map( verification => {
                    return {
                        ...verification,
                        detail: '-',
                        add: '-'
                    }
                })


                this.loading = false
                this.fetch_error = false
            }

            catch(error) {
                this.loading = false
                this.fetch_error = true
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #Verification {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }
            &:focus{
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] { 
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0; 

        }
    }
</style>