<template>
    <div id="Maintenance" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 py-lg-5">
                <p class="manrope-title">
                    Listado de Mantenimiento Vehicular
                </p>
            </div>

            <div class="col-sm-12 col-lg-6 py-0 pb-3 pb-lg-0 py-lg-5  p-0 m-0">
                <div class="row p-0 m-0  justify-content-end align-items-end">
                    <div class="col-12 col-md-4 col-lg-3 p-0 px-3 px-md-3 px-lg-0 m-0 pl-2 pl-md-0 pr-2 mr-0 mr-lg-3 justify-content-end">
                        <li-select @change="filterElements"
                            class="opensans-bold"
                            label="Filtrar por:"
                            variant="secondary"
                            :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                            v-model="filter"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 position-relative">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
                

            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && maintenance_data.length > 0">
                <li-table :data="maintenance_data" 
                    :allow_headers="maintenance_headers"
                    pagination_off>
                    <template v-slot:last_maintenances="data">
                        <span>
                            {{ data.item.last_maintenances ? new Date(data.item.last_maintenances).toLocaleDateString() : 'sin mantenimientos' }} 
                        </span>
                    </template>
                    <template v-slot:current_mileage="data">
                        <span>
                            {{ data.item.current_mileage }} km
                        </span>
                    </template>
                    <template v-slot:mileage_upcoming_maintenances="data">
                        <div class="d-flex align-items-center justify-content-center">
                            <div style="width: 15px; height: 15px; border-radius: 50%;"
                            :style="`background-color: #${data.item.color_status}`"></div>
                            <img :src="radio_icon" class="mx-2" />
                            <span v-if="data.item.mileage_upcoming_maintenances > 0">
                                Quedan: {{ data.item.mileage_upcoming_maintenances }} km
                            </span>
                            <span v-if="data.item.mileage_upcoming_maintenances < 0">
                                Vencido por: {{ data.item.mileage_upcoming_maintenances * -1}} km
                            </span>
                        </div>  
                    </template>
                    <template v-slot:add="data">
                        <button @click.stop="updateMaintenance('update', data.item.id_vehicle)"
                            class="btn btn-outline-primary btn-pill p-0 inter-bold py-1"
                            style="width: 200px;"
                            type="button">
                            Actualizar Kilometraje
                        </button>
                    </template>
                    <template v-slot:next="data">
                        <button @click.stop="updateMaintenance('add', data.item.id_vehicle)"
                            class="btn btn-outline-warning btn-pill p-0 inter-bold py-1"
                            style="width: 200px;"
                            :disabled="data.item.mileage_upcoming_maintenances > 1000"
                            type="button">
                            añadir Mantenimiento
                        </button>
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12" v-if="!fetch_error && !empty_search && maintenance_data.length > 0">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="mr-3 opensans-bold">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        class="form-control bg-secondary text-white border-0"
                        :disabled="page > 1"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Proximos mantenimientos'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal-->

        <li-modal :show_modal="maintenance_modal" :max_width="1200" class="px-3">
                <div class="col-12 col-md-6">
                    <h3 v-if="is_updating_mileage">
                        Adañir kilometraje
                    </h3>
                    <h3 v-if="!is_updating_mileage">>
                        Añadir mantenimento
                    </h3>
                </div>

                <div class="col-12 col-md-6 d-flex justify-content-md-end">
                    <button @click.stop="updateAddMileage"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="clearMaintenanceData"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>


                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Kilometraje actual en tablero"
                        type="number"
                        v-model="mileage"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.mileage">
                        {{ errors.mileage }}
                    </span>
                </div>

        </li-modal>

    </div>
</template>

<script>

    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import radio_icon from 'ASSETS/icons/radio_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex';

    export default {
        data(){
            return {
                lupa_icon,
                radio_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                maintenance_modal: false,
                maintenance_headers: [
                    {key: 'model_name', label: 'Modelo', width: 10},
                    {key: 'plate', label: 'Placas', width: 10},
                    {key: 'last_maintenances', label: 'F. Último', width: 10},
                    {key: 'current_mileage', label: 'Kilometraje actual', width: 10},
                    {key: "mileage_upcoming_maintenances", label: 'Próximo', width: 14},
                    {key: 'add', width: 10},
                    {key: 'next', width: 10}
                ],
                maintenance_data: [],
                mileage: null,
                selected_id: null,
                filter: 'all',
                is_updating_mileage: false,
                errors: {
                    mileage: null
                },
                //pagination
                page: 1,
                rows: 10,
                order_asc: '2',
            }
        },
        computed: {
            ...mapGetters({
                maintenances: 'maintenances/getMaintenances',
                last_page: 'maintenances/getLastPage',
                total_pages: 'maintenances/getTotalPages'
            })
        },
        methods: {
            //helpers
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? true : false;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('maintenances/listMaitenances', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('maintenances/listMaintenances', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('maintenances/listMaintenances', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.maintenances === null) {
                    this.empty_search = true
                    return
                }

                else {
                    this.empty_search = false
                }
                
               this.maintenance_data = this.maintenances.map( maintenances => {
                    return {
                        ...maintenances,
                        add: '-',
                        next: '-'
                    }
                })
                console.log('updated')
            },
            clearMaintenanceData() {
                this.maintenance_modal = false; 
                this.mileage = null; 
                this.is_updating_mileage = false; 
                this.selected_id = null;

                this.errors.mileage = null
            },

            //Maintenances operations
            updateMaintenance(action_type, id){
                switch(action_type){
                    case 'update':
                        this.selected_id = id
                        this.is_updating_mileage = true
                        this.maintenance_modal = true
                        break;
                    case 'add':
                        this.selected_id = id
                        this.maintenance_modal = true
                        break;
                }
            },
            async updateAddMileage(){
                this.errors.mileage = null

                let complete = true

                if(_.isNull(this.mileage) || _.isEmpty(this.mileage)) {
                    complete = false
                    this.errors.mileage = '*Campo obligatorio'
                }

                if(complete) {

                    let payload = {
                        id_vehicle: this.selected_id,
                        current_mileage: this.mileage
                    }

                    if(this.is_updating_mileage) {
                        await this.$store.dispatch('maintenances/addMileage', payload)
                        this.filterElements()
                        this.clearMaintenanceData()
                    }

                    else {
                        await this.$store.dispatch('maintenances/addMaintenance', payload)
                        this.filterElements()
                        this.clearMaintenanceData()
                    }
                }

            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                console.log('next')
                this.page++;
                this.filterElements()
            }
        },
        async created(){
            try {

                //lista de mantenimientos
                await this.$store.dispatch('maintenances/listMaintenances', {datatable: true, page: this.page, rows: this.rows, status: null, order_asc: true} )

                this.maintenance_data = this.maintenances.map( maintenance => {
                    return {
                        ...maintenance,
                        add: '-',
                        next: '-'
                    }
                })

                this.loading = false
                this.fetch_error = false
            }

            catch(error)  {
                this.loading = false
                this.fetch_error = true
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #Maintenance {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }
            &:focus{
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] { 
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0; 

        }
    }
</style>