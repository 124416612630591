<template>
    <div id="Administration" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 py-lg-5">
                <p class="manrope-title">
                    Listado de Administración de Unidad
                </p>
            </div>

            <div class="col-sm-12 col-lg-6 py-0 pb-3 pb-lg-0 py-lg-5  p-0 m-0">
                <div class="row p-0 m-0  justify-content-end align-items-end">
                    <div class="col-12 col-md-4 col-lg-3 p-0 px-3 px-md-3 px-lg-0 m-0 pl-2 pl-md-0 pr-2 mr-0 mr-lg-3 justify-content-end">
                        <li-select @change="filterElements"
                            class="opensans-bold"
                            label="Filtrar por:"
                            variant="secondary"
                            :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                            v-model="filter"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 p-0 px-3 px-md-0 m-0 my-3 my-md-0 pl-2 pl-md-0 justify-content-end">
                        <button @click.stop="vehicles_modal = true"
                            class="btn btn-white btn-pill w-100 opensans-bold"
                            type="button">
                            Añadir Vehiculo
                        </button>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 position-relative">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
                

            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>

            <div class="col-12" v-if="empty_search">
                <p>
                    No existen elementos con esos parametros de búsqueda.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search && vehicles_data.length > 0">
                <li-table :data="vehicles_data" 
                    :allow_headers="vehicles_headers"
                    pagination_off>

                    <template v-slot:id_rental="data">
                        <div class="w-100 flex-center">
                            <div style="width: 20px; height: 20px; border-radius: 100%;"
                                :class="`${data.value ? 'bg-success' : 'bg-danger' }`">
                            </div>
                        </div>
                    </template>
                    <template v-slot:rental_rate="data">
                        <span>${{ formatAmount(data.item.rental_rate, 2) }}</span>
                    </template>
                    <template v-slot:acquisition_cost="data">
                        <span>${{ formatAmount(data.item.acquisition_cost, 2) }}</span>
                    </template>
                    <template v-slot:details="data">
                        <button @click.stop="$router.push({ name: 'car-profile', params: { id: data.item.id_vehicle} })"
                            class="btn btn-outline-primary btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Detalles
                        </button>
                    </template>
                    <template v-slot:edit="data">
                        <button @click.stop="selectVehicle('edit', data.item)"
                            class="btn btn-outline-warning btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Editar
                        </button>
                    </template>
                    <template v-slot:delete="data">
                        <button @click.stop="selectVehicle('delete', data.item)" v-if="data.item.active"
                            class="btn btn-outline-danger btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Eliminar
                        </button>
                        <button @click.stop="selectVehicle('activate', data.item)" v-if="!data.item.active"
                            class="btn btn-outline-primary btn-pill p-0 interbold"
                            style="width: 90px;"
                            type="button">
                            Activar
                        </button>
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12" v-if="!fetch_error && !empty_search && vehicles_data.length > 0">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="mr-3 opensans-bold">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        class="form-control bg-secondary text-white border-0"
                        :disabled="page > 1"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal-->

        <li-modal :show_modal="vehicles_modal" :max_width="1200" class="px-3">
                <div class="col-12 col-md-6">
                    <h3>
                       Agregar o editar unidad
                    </h3>
                </div>

                <div class="col-12 col-md-6 d-flex justify-content-md-end">
                    <button @click.stop="set_documentation = !set_documentation" v-if="selected_id"
                        class="btn btn-pill btn-primary"
                        style="height: 35px; padding: 0 20px;">
                            {{  set_documentation ? 'Información General' : 'Documentos' }}
                    </button>

                    <button @click.stop="createEditVehicle"
                        class="btn btn-pill ml-3"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                            Aplicar
                    </button>
                                
                    <button @click.stop="vehicles_modal = false; clearVehicleData()"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

                <div class="col-12 m-0" v-if="!set_documentation">
                    <div class="row pt-4 m-0">

                        <div class="col-12 pt-3">
                            <strong class="text-secondary">Del vehículo</strong>
                            <hr class="rounded bg-secondary mt-0">
                        </div>

                        <div class="row">
                            <div class="col-12 col-md-4 p-2 opensans-bold">
                                <p>
                                    Foto del vehiculo
                                </p>
                                <li-dropfile _id="vehicle_picture" v-if="!vehicle_picture"
                                    @charged="checkFile"/>

                                <div class="d-flex flex-column  py-2" v-if="vehicle_picture">
                                    <img class="img-fluid"
                                        :src="vehicle_picture" />
                                        <button @click.stop="vehicle_picture= null"
                                            class="btn btn-primary mt-2"
                                            type="button">
                                            Cambiar
                                        </button>
                                </div>
                            </div>

                            <div class="col-12 col-md-8">
                                <div class="row">
                                    <div class="col-12 col-md-6 p-2 opensans-bold">
                                    <li-select label="Marca:" @change="searchModels"
                                        hidden_option="Seleccione..."
                                        :options="brand_options"
                                        v-model="id_brand"
                                        variant="secondary"
                                        full>
                                    </li-select>
                                    <span class="text-danger" v-if="errors.id_brand">
                                        {{ errors.id_brand}}
                                    </span>
                                </div>

                                <div class="col-12 col-md-6 p-2 opensans-bold">
                                    <li-select label="Modelo:"
                                        hidden_option="Seleccione..."
                                        :options="model_options"
                                        v-model="id_model"
                                        variant="secondary"
                                        full>
                                    </li-select>
                                    <span class="text-danger" v-if="errors.id_model">
                                        {{ errors.id_model}}
                                    </span>
                                </div>

                                <div class="col-12 col-md-6 p-2 opensans-bold">
                                    <li-input label="Año:"
                                        type="text"
                                        v-model="year"
                                        placeholder="Año"
                                        full>
                                    </li-input>

                                    <span class="text-danger" v-if="errors.year">
                                        {{ errors.year}}
                                    </span>
                                </div>

                                <div class="col-12 col-md-6 p-2 opensans-bold">
                                    <li-select label="Color:"
                                        hidden_option="Seleccione..."
                                        :options="colors_options"
                                        v-model="id_color"
                                        variant="secondary"
                                        color_selector
                                        full>
                                    </li-select>

                                    <span v-if="errors.id_color">
                                        {{ errors.id_color}}
                                    </span>
                                </div>

                                <div class="col-12 col-md-6 p-2 opensans-bold">
                                    <li-input label="Número de serie:"
                                        type="text"
                                        v-model="serial_number"
                                        placeholder="Número de serie"
                                        full>
                                    </li-input>

                                    <span class="text-danger"  v-if="errors.serial_number">
                                        {{ errors.serial_number}}
                                    </span>
                                </div>

                                <div class="col-12 col-md-6 p-2 opensans-bold">
                                    <li-input label="Número de motor:"
                                        type="text"
                                        v-model="motor_number"
                                        placeholder="Número de serie"
                                        full>
                                    </li-input>

                                    <span class="text-danger"  v-if="errors.motor_number">
                                        {{ errors.motor_number}}
                                    </span>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 pt-3">
                            <strong class="text-secondary">Más</strong>
                            <hr class="rounded bg-secondary mt-0">
                        </div>

                        <div class="col-12 col-md-4 p-2 opensans-bold">
                            <li-select label="Propietario:"
                                hidden_option="Seleccione..."
                                :options="owner_options"
                                v-model="owner_name"
                                variant="secondary"
                                full>
                            </li-select>
                            <span class="text-danger" v-if="errors.owner_name">
                                {{ errors.owner_name}}
                            </span>
                        </div>

                        <div class="col-12 col-md-4 p-2 opensans-bold">
                            <li-input label="Costo de adquisición"
                                type="text"
                                v-model="acquisition_cost"
                                placeholder="costo de adquisición"
                                full>
                            </li-input>

                            <span class="text-danger"  v-if="errors.acquisition_cost">
                                {{ errors.acquisition_cost}}
                            </span>
                        </div>

                        <div class="col-12 col-md-4 p-2 opensans-bold">
                            <li-input label="Costo de renta:"
                                type="text"
                                v-model="rental_rate"
                                placeholder="Renta"
                                full>
                            </li-input>

                            <span class="text-danger"  v-if="errors.rental_rate">
                                {{ errors.rental_rate}}
                            </span>
                        </div>
                        
                        <div class="col-12 pt-3">
                            <strong class="text-secondary">Placas y Verificación vehicular</strong>
                            <hr class="rounded bg-secondary mt-0">
                        </div>

                        <div class="col-12">
                            <li-checkbox label="Placa disponible"
                                v-model="available_plate"
                                theme="dark"
                                variant="info"
                                >
                            </li-checkbox>
                        </div>

                        <div class="col-6  p-2 opensans-bold" v-if="available_plate">
                            <li-input label="No. de placas"
                                type="text"
                                v-model="plate"
                                placeholder="número de placas"
                                :disabled="selected_id"
                                full>
                            </li-input>
                            <span class="text-danger" v-if="errors.plate">
                                {{ errors.plate}}
                            </span>
                        </div>

                        <div class="col-6  p-2 opensans-bold" v-if="available_plate">
                            <li-select label="Región de las placas"
                                :options="region_options"
                                v-model="id_region" 
                                :disabled="selected_id"
                                variant="secondary"
                                full/>
                            <span class="text-danger" v-if="errors.id_region">
                                {{ errors.id_region}}
                            </span>
                        </div>

                        <div class="col-12 col-md-6 p-2 opensans-bold" v-if="available_plate">
                            <li-select label="Holograma:"
                                hidden_option="Seleccione..."
                                :options="[{id: 'select', label: 'Seleccione'},{id: '00', label: '00'},{id: '0', label: '0'},{id: '1', label: '1'},{id: '2', label: '2'},{id: 'E', label: 'E'}]"
                                v-model="hologram"
                                variant="secondary"
                                full>
                            </li-select>
                            <span class="text-danger"  v-if="errors.hologram">
                                {{ errors.hologram}}
                            </span>
                        </div>

                        <div class="col-12 col-md-6 p-2 opensans-bold" v-if="available_plate">
                            <li-input label="Fecha de la ultima Verificación vehicular:"
                                type="date"
                                v-model="last_verification"
                                :disabled="selected_id"
                                placeholder="Ultima Verificación vehicular"
                                full>
                            </li-input>

                            <span class="text-danger"  v-if="errors.last_verification">
                                {{ errors.last_verification}}
                            </span>
                        </div>

                        <div class="col-12 pt-3">
                            <strong class="text-secondary">Kilometraje</strong>
                            <hr class="rounded bg-secondary mt-0">
                        </div>


                        <div class="col-12 col-md-6 p-2 opensans-bold">
                            <li-input label="Kilometraje:"
                                type="number"
                                v-model="current_mileage"
                                placeholder="Kilometraje"
                                full>
                            </li-input>

                            <span class="text-danger"  v-if="errors.current_mileage">
                                {{ errors.current_mileage}}
                            </span>
                        </div>

                    </div>
                </div>

                <div class="col-12 p-0 m-0" v-if="set_documentation">

                    <div class="row p-0 m-0">

                        <div class="col-5  p-2 opensans-bold">
                            <li-input label="No. de placas"
                                type="text"
                                v-model="plate"
                                placeholder="número de placas"
                                full>
                            </li-input>
                        </div>

                        <div class="col-5  p-2 opensans-bold">
                            <li-select label="Región de las placas"
                                :options="region_options"
                                v-model="id_region" 
                                variant="secondary"
                                full/>
                            <span class="text-danger" v-if="errors.id_region">
                                {{ errors.id_region}}
                            </span>
                        </div>

                        <div class="col-2 p-2 d-flex align-items-end">
                            <button @click.stop="setPlates"
                                class="btn btn-primary btn-pill"
                                type="button">
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
                



        </li-modal>

        <li-modal :show_modal="delete_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h3>
                        ¿Esta seguro de eliminar este Vehiculo? 
                        <br>
                        Esta acción no puede revertirse
                    </h3>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="deleteVehicle"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="delete_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="activate_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de activar este vehiculo? 
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="activateVehicle"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="activate_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

    </div>
</template>

<script>

    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                lupa_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                vehicles_modal: false,
                delete_modal: false,
                set_documentation: false,
                activate_modal: false,
                last_verification: null,
                vehicles_headers: [
                    {key: 'id_vehicle', label: '#'},
                    {key: 'brand_name', label: 'Marca'},
                    {key: 'model_name', label: 'Modelo'},
                    {key: 'rental_rate', label: 'Renta'},
                    {key: 'color_name', label: 'Color'},
                    {key: 'vehicle_type_name', label: 'Tipo de vehiculo'},
                    {key: 'serial_number', label: 'Número de serie'},
                    {key: 'plate', label: 'Placas'},
                    {key: 'year', label: 'Año'},
                    {key: 'acquisition_cost', label: 'Costo de adquisición'},
                    {key: 'id_rental', label: 'Rentado'},
                    {key: 'details'},
                    {key: 'edit'},
                    {key: 'delete'}
                ],
                vehicles_data: [],
                brand_options: [],
                model_options: [],
                colors_options: [],
                owner_options: [],
                vehicle_picture: null,
                id_color: '0',
                id_model: '0',
                id_region: '0',
                id_brand: '0',
                hologram: 'select',
                selected_id: null,
                year: null,
                rental_rate: null,
                acquisition_cost: null,
                serial_number: null,
                owner_name: '0',
                current_mileage: null,
                motor_number: null,
                available_plate: false,
                errors: {
                    serial_number: null,
                    plate: null,
                    year: null,
                    id_color: null,
                    id_model: null,
                    id_brand: null,
                    rental_rate: null,
                    acquisition_cost: null,
                    last_verification: null,
                    motor_number: null,
                    id_region: null,
                    hologram: null,
                    owner_name: null
                },
                plate: null,
                filter: 'active',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2'
            }
        },
        computed: {
            ...mapGetters({
                vehicles: 'unit_administration/getUnits',
                vehicle: 'unit_administration/getUnit',
                models: 'unit_administration/getModels',
                colors: 'colors/getColors',
                owners: 'owners/getOwners',
                brands: 'brands/getBrands',
                regions: 'regions/getRegions',
                last_page: 'unit_administration/getLastPage',
                total_pages: 'unit_administration/getTotalPages'
            })
        },
        methods: {
            //helpers
            clearVehicleData() {
                this.year = null
                this.acquisition_cost = null
                this.rental_rate = null
                this.id_color = '0'
                this.id_model = '0'
                this.serial_number = null
                this.plate = null
                this.vehicle_picture = null
                this.id_brand = '0'
                this.rental_rate = null
                this.acquisition_cost = null
                this.last_verification = null
                this.motor_number = null
                this.id_region = '0'
                this.owner_name = '0'
                this.current_mileage = 0;
                this.hologram = 'select'
                this.selected_id = null
                this.available_plate = false

                for(let error in this.errors){
                    this.errors[error] = null
                }
            },
            checkFile(data) {
                this.vehicle_picture = data.url
                
            },
            async searchModels(id) {
                await this.$store.dispatch('unit_administration/searchByBrand', { id_brand: id})
                this.model_options = this.models
            },
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }
                
                let order = this.order_asc == '2' ? false : true;

                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('unit_administration/listVehicles', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('unit_administration/listVehicles', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('unit_administration/listVehicles', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.vehicles === null) {
                    this.empty_search = true
                    return
                }

                else {
                    this.empty_search = false
                }
                
               this.vehicles_data = this.vehicles.map( vehicle => {
                    return {
                        ...vehicle,
                        details: '-',
                        edit: '-',
                        delete: '-'
                    }
                })
            },

            //vehicles crud
            async selectVehicle(type, data) {
                switch(type) {
                    case 'edit':

                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }

                        this.selected_id = data.id_vehicle
                        await this.$store.dispatch('unit_administration/viewVehicle', { id_vehicle: this.selected_id})
                        this.id_color = this.vehicle.id_color.toString()
                        this.id_brand = this.vehicle.id_brand.toString()
                        await this.searchModels(this.vehicle.id_brand)
                        this.id_model = this.vehicle.id_model.toString()
                        this.year = this.vehicle.year
                        this.rental_rate = this.vehicle.rental_rate.toString()
                        this.acquisition_cost = this.vehicle.acquisition_cost.toString()
                        this.serial_number = this.vehicle.serial_number
                        this.available_plate = this.vehicle.plate ? true : false
                        this.plate = this.vehicle.plate
                        this.id_region = this.vehicle.id_region ?? '0';
                        this.owner_name = this.vehicle.id_owner ?? '0';
                        this.motor_number = this.vehicle.motor_number;
                        this.last_verification = this.vehicle.last_verification
                        this.hologram = this.vehicle.hologram ?? 'select',
                        this.current_mileage = this.vehicle.mileage_maintenance.length > 0 ? this.vehicle.mileage_maintenance[0].current_mileage : null
                        this.last_verification = this.vehicle.verifications.length > 0 ? this.dateParser(this.vehicle.verifications[0].date) : null
                        this.vehicle_picture = this.vehicle.picture
                        this.vehicles_modal = true
                        return;
                    case 'delete':
                        this.selected_id = data.id_vehicle
                        this.delete_modal = true
                        return;
                    case 'activate':
                        this.selected_id = data.id_spare_part
                        this.activate_modal = true
                        return;
                    default:
                        console.log('No es un caso valido')
                        return
                }
            },
            async createEditVehicle() {

                let complete = true;
                for(let error in this.errors){
                    this.errors[error] = null
                }

                if(this.id_color == '0') {
                    complete = false
                    this.errors['id_color'] = '*Campo obligatorio'
                }

                if(this.id_brand == '0') {
                    complete = false
                    this.errors['id_brand'] = '*Campo obligatorio'
                }

                if(this.id_model == '0') {
                    complete = false
                    this.errors['id_model'] = '*Campo obligatorio'
                }
                
                console.log(this.owner_name)
                if(this.owner_name == '0') {
                    complete = false
                    this.errors['owner_name'] = '*Campo obligatorio'
                }

                if(this.id_brand == '0') {
                    complete = false
                    this.errors['id_brand'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.year) || _.isNull(this.year)) {
                    complete = false
                    this.errors['year'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.serial_number) || _.isNull(this.serial_number)) {
                    complete = false
                    this.errors['serial_number'] = '*Campo obligatorio'
                }

                if(_.isNull(this.current_mileage)) {
                    complete = false
                    this.errors['current_mileage'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.acquisition_cost) || _.isNull(this.acquisition_cost)) {
                    complete = false
                    this.errors['acquisition_cost'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.motor_number) || _.isNull(this.motor_number)) {
                    complete = false
                    this.errors['motor_number'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.rental_rate) || _.isNull(this.rental_rate)) {
                    complete = false
                    this.errors['rental_rate'] = '*Campo obligatorio'
                }

                if(this.available_plate) {
                    if(this.selected_id == null && (_.isEmpty(this.plate) || _.isNull(this.plate))) {
                        complete = false
                        this.errors['plate'] = '*Campo obligatorio'
                    }

                    if(this.selected_id == null && this.id_region == '0') {
                        complete = false
                        this.errors['id_region'] = '*Campo obligatorio'
                    }

                    if(this.hologram == 'select') {
                        complete = false
                        this.errors['hologram'] = '*Campo obligatorio'
                    }

                    if(this.selected_id == null && (_.isEmpty(this.last_verification) || _.isNull(this.last_verification))) {
                        complete = false
                        this.errors['last_verification'] = '*Campo obligatorio'
                    }
                }

                if(complete) {

                    const payload = {
                        id_color: this.id_color,
                        id_model: this.id_model,
                        rental_rate: this.formatAmount(this.rental_rate, 2), 
                        acquisition_cost: this.formatAmount(this.acquisition_cost, 2), 
                        current_mileage: this.current_mileage,
                        year: this.year,
                        serial_number: this.serial_number,
                        hologram: this.hologram,
                        id_owner: this.owner_name,
                        last_verification: this.last_verification,
                        motor_number: this.motor_number,
                        id_brand: this.id_brand ,
                        plate: this.plate,
                        id_region: this.id_region,
                        picture: this.vehicle_picture
                    }

                    console.log(payload)

                    if(this.selected_id) {
                        payload.id_vehicle = this.selected_id
                        await this.$store.dispatch('unit_administration/updateVehicle', payload)
                        this.filterElements()
                        this.clearVehicleData()
                        this.vehicles_modal = false
                        this.selected_id = null
                    }

                    else {
                        await this.$store.dispatch('unit_administration/addVehicle', payload)
                        this.filterElements()
                        this.clearVehicleData()
                        this.vehicles_modal = false
                    }
                }
            },
            async deleteVehicle() {
                await this.$store.dispatch('unit_administration/deleteVehicle', { id_vehicle: this.selected_id, status: 'inactive'})
                this.filterElements()
                this.delete_modal = false
                this.selected_id = null
            },
            async activateVehicle(){
                await this.$store.dispatch('unit_administration/deleteVehicle', { id_vehicle: this.selected_id, status: 'active'})
                this.filterElements()
                this.activate_modal = false
                this.selected_id = null
            },
            async setPlates() {
                if(!this.plate || this.id_region == '0') {
                    return
                }

                else {

                    let payload = {
                        plates: this.plate,
                        id_vehicle: this.selected_id,
                        id_region: this.id_region
                    }

                    await this.$store.dispatch('unit_administration/addPlates', payload)
                    this.filterElements()
                    this.clearVehicleData()

                    this.vehicles_modal = false
                    this.selected_id = null
                    this.set_documentation = false
                }
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                console.log('next')
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            try {
                
                //opciones de marcas
                await this.$store.dispatch('brands/listBrands', {datatable: false, status: 'active'})

                this.brand_options = this.brands.map( brand => {
                    return {
                        id: brand.id_brand,
                        label: brand.name
                    }
                })

                //opciones de colores
                await this.$store.dispatch('colors/listColors', { datatable: false, status: 'active'})
                
                this.colors_options = this.colors.map( color => {
                    return {
                        id: color.id_color,
                        label: color.name,
                        hex: color.hex
                    }
                })

                this.colors_options = [
                    {id: '0', label: 'Seleccione'},
                    ...this.colors_options
                ]

                //opciones de propietarios
                await this.$store.dispatch('owners/listOwners', {datatable: false, status: 'active'})
                
                this.owner_options = this.owners.map( owner => {
                    return {
                        id: owner.id_owner,
                        label: `${ owner.first_name } ${ owner.last_name } ${ owner.second_surname }`
                    }
                })

                this.owner_options = [
                    {id: '0', label: 'Seleccione'},
                    ...this.owner_options
                ]

                //opciones de regiones
                await this.$store.dispatch('regions/listRegions', {datatable: false, status: 'active'})
                
                this.region_options = this.regions.map( region => {
                    return {
                        id: region.id_region,
                        label: region.name
                    }
                })

                this.region_options = [
                    {id: '0', label: 'Seleccione'},
                    ...this.region_options
                ]

                //lista de unidades
                await this.$store.dispatch('unit_administration/listVehicles', {datatable: true, page: this.page, rows: this.rows, status: 'active', order_asc: false})
                this.vehicles_data = this.vehicles.map( vehicle => {
                    return {
                        ...vehicle,
                        details: '-',
                        edit: '-',
                        delete: '-'
                    }
                })

                this.loading = false
                this.fetch_error = false
            }

            catch(error) {
                this.loading = false
                this.fetch_error = true
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #Administration {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }

            &:focus{
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] { 
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0; 

        }
    }
</style>