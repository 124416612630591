<template>
    <section class="container-fluid p-0 m-0">

        <div class="row w-100 bg-gray align-items-center justify-content-center" 
			style="min-height: 80px;">
            <div class="col-12 pt-3">
                <li-tab @change="setTab($event)"
                    :data="tabs" :tab_selected="selected_tab">
                </li-tab>
            </div>

            <div class="col-12 d-flex justify-content-end align-items-end">
                    <div v-for="(symbol, index) in symbology" :key="index"
                        class="d-flex mt-4">

                        <img :src="symbol.color" width="20px" height="20px"/>
                        <p class="px-4">
                            {{ symbol.label}}
                        </p>
                    </div>
            </div>  
        </div>
        
        <div class="row p-0 m-0">
            <div class="col-12 p-0">
                <administration v-if="selected_tab == '1'"/>
                <verification v-if="selected_tab == '2'"/>
                <assurance v-if="selected_tab == '3'"/>
                <maintenance v-if="selected_tab == '4'"/>
            </div>
        </div>

    </section>
</template>

<script>
    //images
    import green_dot from 'ASSETS/images/green_icon.png'
    import yellow_dot from 'ASSETS/images/yellow_icon'
    import red_dot from 'ASSETS/images/red_icon.png'
    
    //Subviews
    import Verification  from './units/Verification.vue'
    import Assurance from './units/Insurance.vue'
    import Maintenance from './units/Maintenance.vue'
    import Administration from './units/Administration.vue'

    export default {
        components: {
            Verification,
            Assurance,
            Maintenance,
            Administration
        },
        data(){
            return {
                tabs: [
                    {key: '1', label: 'Administración de Unidad'},
                    {key: '3', label: 'Seguro'},
                    {key: '2', label: 'Verificación'},
                    {key: '4', label: 'Mantenimiento'}
                ],
                selected_tab: '1',
                symbology: [
                    {color: green_dot, label: 'Preventivo'},
                    {color: yellow_dot, label: 'Precaución'},
                    {color: red_dot, label: 'Crítico'}
                ],
            }
        },
        methods: {
            setTab(data) {
                this.selected_tab = data.key
            }
        }
    }
</script>

<style scoped>

</style>

