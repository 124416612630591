<template>
    <div id="Insurance" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 py-lg-5">
                <p class="manrope-title">
                    Listado de Seguro Vehicular
                </p>
            </div>

            <div class="col-sm-12 col-lg-6 py-0 pb-3 pb-lg-0 py-lg-5  p-0 m-0">
                <div class="row p-0 m-0  justify-content-end align-items-end">
                    <div class="col-12 col-md-4 col-lg-3 p-0 px-3 px-md-3 px-lg-0 m-0 pl-2 pl-md-0 pr-2 mr-0 mr-lg-3 justify-content-end">
                        <li-select @change="filterElements"
                            class="opensans-bold"
                            label="Filtrar por:"
                            variant="secondary"
                            :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                            v-model="filter"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 p-0 px-3 px-md-0 m-0 my-3 my-md-0 pl-2 pl-md-0 justify-content-end">
                        <button @click.stop="insurance_modal = true"
                            class="btn btn-white btn-pill w-100 opensans-bold"
                            type="button">
                            Añadir Seguro
                        </button>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 position-relative">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
                

            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>

            <div class="col-12" v-if="empty_search">
                <p>
                    No existen elementos con esos parametros de búsqueda.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search && insurance_data.length > 0">
                <li-table :data="insurance_data" 
                    :allow_headers="insurance_headers"
                    pagination_off>
                    <template v-slot:premium_value="data">
                        <span>
                            $ {{ formatAmount(data.item.premium_value, 2)}}
                        </span>
                    </template>
                    <template v-slot:date_init="data">
                        <span>
                             {{ new Date(data.item.date_init).toLocaleDateString()}} 
                        </span>
                    </template>
                    <template v-slot:remaining_days="data">
                        <div class="d-flex align-items-end justify-content-center">
                            <div class="mb-1" style="width: 15px; height: 15px; border-radius: 50%; background-color: #34A853;" v-if="(data.item.remaining_days > 30)" ></div>
                            <div class="mb-1" style="width: 15px; height: 15px; border-radius: 50%; background-color: #FBBC05;" v-if="data.item.remaining_days < 30 && data.item.remaining_days >= 15"></div>
                            <div class="mb-1" style="width: 15px; height: 15px; border-radius: 50%; background-color: #EA4335;" v-if="data.item.remaining_days < 15"></div>
                            <span class="ml-3">
                                {{ data.item.remaining_days > 0 ? data.item.remaining_days : `Vencido por ${ Math.abs(data.item.remaining_days) } dias` }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:date_end="data">
                        <span>
                             {{ new Date(data.item.date_end).toLocaleDateString()}} 
                        </span>
                    </template>
                    <template v-slot:delete="data">
                        <button v-if="!data.item.active"
                            class="btn btn-outline-secondary btn-pill p-0 inter-bold"
                            style="width: 95px;"
                            type="button">
                            finalizado
                        </button>
                        <button @click.stop="selectInsurance(data.item)" v-if="false"
                            class="btn btn-outline-danger btn-pill p-0 inter-bold"
                            style="width: 95px;"
                            type="button">
                            Eliminar
                        </button>
                        <button v-if="(data.item.active)"
                            class="btn btn-outline-warning btn-pill p-0 inter-bold"
                            style="width: 95px;"
                            type="button">
                            Actual
                        </button>
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12" v-if="!fetch_error && !empty_search && insurance_data.length > 0">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="mr-3 opensans-bold">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        class="form-control bg-secondary text-white border-0"
                        :disabled="page > 1"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'proximos a vencer'}, {id: '2', label: 'Antiguos primero'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal-->

        <li-modal :show_modal="insurance_modal" :max_width="1200" class="px-3">
                <div class="col-12 col-md-6">
                    <h3>
                        Añadir Seguro
                    </h3>
                </div>

                <div class="col-12 col-md-6 d-flex justify-content-md-end">
                    <button @click.stop="addInsurance"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="clearInsuranceData"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-select label="Compañia de seguro"
                        variant="secondary"
                        :options="company_options"
                        v-model="id_insurance_company"
                        full>
                    </li-select>
                    <span class="text-danger" v-if="errors.id_insurance_company">
                        {{ errors.id_insurance_company }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-select label="Vehiculo"
                        variant="secondary"
                        :options="vehicle_options"
                        v-model="id_vehicle"
                        full>
                    </li-select>
                    <span class="text-danger" v-if="errors.id_vehicle">
                        {{ errors.id_vehicle }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Número de Póliza"
                        placeholder="Número de Póliza"
                        v-model="policy_number"
                        type="text"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.policy_number">
                        {{ errors.policy_number }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Beneficiario"
                        placeholder="Beneficiario"
                        v-model="beneficiarys_name"
                        type="text"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.beneficiarys_name">
                        {{ errors.beneficiarys_name }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Prima del seguro"
                        placeholder="Prima del seguro"
                        v-model="premium_value"
                        type="number"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.premium_value">
                        {{ errors.premium_value }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Valor total del seguro"
                        placeholder="Valor total del seguro"
                        v-model="insured_amount"
                        type="number"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.insured_amount">
                        {{ errors.insured_amount }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-select label="Periodicidad"
                        variant="secondary"
                        :options="[{id:0, label: 'Seleccione'},{id: 'bimestral', label: 'Bimestral'},{id: 'semestral', label: 'Semestral'},{id: 'anual', label: 'Anual'}]"
                        v-model="periodicity"
                        full>
                    </li-select>
                    <span class="text-danger" v-if="errors.periodicity">
                        {{ errors.periodicity }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Fecha de inicio"
                        v-model="date_init"
                        type="date"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.date_init">
                        {{ errors.date_init }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Fecha fin"
                        v-model="date_end"
                        type="date"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.date_end">
                        {{ errors.date_end }}
                    </span>
                </div>

                <div class="col-12 text-gray">
                    <p>Documentos</p>
                    <hr style="border: 0.5px solid gray">
                </div>
                
                <!--drop files -->
                <div class="col-12">    
                    <div class="row">
                        <div class="col-3">
                            <p>
                                Evidencia
                            </p>
                            <li-dropfile _id="insurance_file" v-if="!picture"
                                @charged="checkFile"/>

                            <div class="d-flex flex-column  py-2" v-if="picture">
                                <img class="img-fluid"
                                    :src="picture" />
                                    <button @click.stop="picture = null"
                                        class="btn btn-primary mt-2"
                                        type="button">
                                        Cambiar
                                    </button>
                            </div>
                            <span class="text-danger" v-if="errors.picture">
                                {{ errors.picture}}
                            </span>
                        </div>
                    </div>
                </div>

        </li-modal>

        <li-modal :show_modal="delete_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de eliminar esta marca? 
                        <br>
                        Esta acción no puede revertirse
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="deleteBrand"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="delete_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

    </div>
</template>

<script>

    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                lupa_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                insurance_modal: false,
                delete_modal: false,
                insurance_headers: [
                    {key: 'insurance_company_name', label: 'Compañia de seguro'},
                    {key: 'model_name', label: 'Modelo'},
                    {key: 'plate', label: 'Placas'},
                    {key: 'policy_number', label: 'Número de póliza'},
                    {key: 'premium_value', label: 'Prima del seguro'},
                    //{key: 'insured_amount', label: 'Valor asegurado'},
                    {key: 'periodicity', label: 'Periodicidad'},
                    //{key: 'date_init', label: 'Fecha Inicio'},
                    {key: 'remaining_days', label: 'Dias restantes'},
                    {key: 'date_end', label: 'Fecha Fin'},
                    {key: 'delete'}
                ],
                insurance_data: [],
                vehicle_options: [],
                company_options: [],
                filter: 'all',
                selected_id: null,
                id_vehicle: '0',
                id_insurance_company: '0',
                policy_number: null,
                premium_value: null,
                insured_amount: null,
                picture: null,
                beneficiarys_name: null,
                deductible: null,
                periodicity: '0',
                date_init: null,
                date_end: null,
                errors: {
                    id_vehicle: null,
                    policy_number: null,
                    premium_value: null,
                    periodicity: null,
                    date_init: null,
                    date_end: null,
                    beneficiarys_name: null,
                    insured_amount: null,
                    picture: null
                },
                //pagination
                page: 1,
                rows: 10,
                order_asc: '1'
            }
        },
        computed: {
            ...mapGetters({
                insurances: 'insurances/getInsurances',
                insurance: 'insurances/getInsurance',
                companies: 'insurance_companies/getCompanies',
                vehicles: 'unit_administration/getUnits',
                last_page: 'insurances/getLastPage',
                total_pages: 'insurances/getTotalPages'
            })
        },
        methods: {
            //helpers
            checkFile(data) {
                this.picture = data.url
            },
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }
                
                let order = this.order_asc == '2' ? false : true;

                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('insurances/listInsurances', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('insurances/listInsurances', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('insurances/listInsurances', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.insurances === null) {
                    this.empty_search = true
                    return
                }

                else {
                    this.empty_search = false
                }
                
               this.insurance_data = this.insurances.map( insurance => {
                    return {
                        ...insurance,
                        edit: '-',
                        delete: '-'
                    }
                })
            },
            async selectInsurance(type, data) {
                switch(type) {
                    case 'delete':
                        this.selected_id = data.id_insurance
                        this.delete_modal = true
                        return;
                    default:
                        console.log('No es un caso valido')
                        return
                }
            },
            millisecondsToDays(millisecods){
                return Math.ceil(millisecods / (1000 * 3600 * 24))
            },
            clearInsuranceData(){
                this.id_vehicle = '0',
                this.policy_number = null
                this.premium_value = null
                this.insured_amount = null
                this.deductible = null
                this.periodicity = '0'
                this.date_init = null
                this.date_end = null
                this.id_insurance_company = '0',

                this.insurance_modal = false
                
                for(let error in this.errors) {
                    this.errors[error] = null
                }
            },
            //insurance operations
            async addInsurance() {

                for(let error in this.errors) {
                    this.errors[error] = null
                }
                let complete = true

                if(_.isEmpty(this.id_vehicle) || _.isNull(this.id_vehicle) || this.id_vehicle == '0') {
                    complete = false
                    this.errors['id_vehicle'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.policy_number) || _.isNull(this.policy_number)) {
                    complete = false
                    this.errors['policy_number'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.beneficiarys_name) || _.isNull(this.beneficiarys_name)) {
                    complete = false
                    this.errors['beneficiarys_name'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.premium_value) || _.isNull(this.premium_value)) {
                    complete = false
                    this.errors['premium_value'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.insured_amount) || _.isNull(this.insured_amount)) {
                    complete = false
                    this.errors['insured_amount'] = '*Campo obligatorio'
                }


                if(_.isEmpty(this.id_insurance_company) || _.isNull(this.id_insurance_company) || this.id_insurance_company == '0') {
                    complete = false
                    this.errors['id_insurance_company'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.periodicity) || _.isNull(this.periodicity) || this.periodicity == '0') {
                    complete = false
                    this.errors['periodicity'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.date_init) || _.isNull(this.date_init)) {
                    complete = false
                    this.errors['date_init'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.date_end) || _.isNull(this.date_end)) {
                    complete = false
                    this.errors['date_end'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.picture) || _.isNull(this.picture)) {
                    complete = false
                    this.errors['picture'] = '*Campo obligatorio'
                }

                let difference = new Date(this.date_end).getTime() - new Date(this.date_init).getTime()
                let days = this.millisecondsToDays(difference);
                
                if(days < 58 && days > 62 && this.periodicity == 'bimestral') {
                    complete = false
                    this.errors['date_end'] = 'No es una fecha valida para el periodo seleccionado'
                }

                if(days < 181 &&  days > 184  &&this.periodicity == 'semestral') {
                    complete = false
                    this.errors['date_end'] = 'No es una fecha valida para el periodo seleccionado'
                }

                if(days < 365 && days > 366 && this.periodicity == 'anual') {
                    complete = false
                    this.errors['date_end'] = 'No es una fecha valida para el periodo seleccionado'
                }


                if(complete) {
                    const payload = {
                        id_vehicle: this.id_vehicle,
                        id_insurance_company: this.id_insurance_company,
                        policy_number: this.policy_number,
                        premium_value: this.premium_value,
                        periodicity: this.periodicity,
                        date_init: this.date_init,
                        date_end: this.date_end,
                        beneficiarys_name:this.beneficiarys_name,
                        insured_amount: this.insured_amount,
                        picture: this.picture
                    }

                    await this.$store.dispatch('insurances/addInsurance', payload)
                    this.filterElements()
                    this.clearInsuranceData()
                }

            }
        },
        async created() {
            try {

                //opciones compañias de seguro
                await this.$store.dispatch('insurance_companies/listCompanies', { datatable: false, status: 'active'})
                this.company_options = this.companies.map( company => {
                    return {
                        id: company.id_insurance_company,
                        label: company.name
                    }
                })

                this.company_options = [
                    { id: 0, label: 'Seleccione' },
                    ...this.company_options
                ]

                //opciones de vehiculos
                await this.$store.dispatch('unit_administration/listVehicles', { datatable: false, status: 'active'})
                this.vehicle_options = this.vehicles.map( vehicle => {
                    return {
                        id: vehicle.id_vehicle,
                        label: `${vehicle.model_name} ${vehicle.plate ? vehicle.plate : 'No hay placas registradas'}`
                    }
                })

                this.vehicle_options = [
                    { id: 0, label: 'Seleccione' },
                    ...this.vehicle_options
                ]

                // lista de seguros
                await this.$store.dispatch('insurances/listInsurances', { datatable: true, page: this.page, rows: this.rows, status: null, order_asc: true})
                this.insurance_data = this.insurances.map( insurance => {
                    return {
                        ...insurance,
                        edit: '-',
                        delete: '-'
                    }
                })

                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                this.loading = false
                this.fetch_error = true
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #Insurance {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }
            &:focus{
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] { 
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0; 

        }
    }
</style>